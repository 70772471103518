import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import { Link } from 'gatsby'
import SEO from "../../components/seo"

export default () => (
  <Layout>

    <SEO title="Jobs at Getlocal"
        description="Join a growing team of experts in software development and travel solutions. " 
        image="/img/people-working.jpg"
        pathname="/jobs/"
    />
    <Hero 
         headerText="Work with us - Jobs at Getlocal" 
         subHeaderText="Join a growing team of experts in software development and travel solutions. "
         heroBanner="/img/people-working.jpg" /> 

    <section className="section content-pages">
      <div className="container">
        <div className="content">
          <div className="columns content is-multiline">
          <div className="column is-12">
              <h3><Link to="/jobs/lead-developer/">Senior / Lead Developer</Link></h3>
              <p>We are looking for a smart and ambitious developer to help us develop the Getlocal SAAS e-commerce and site builder.</p>
              <p><Link to="/jobs/lead-developer/" className="btn">Read more</Link></p>
            </div>
            <div className="column is-12">
              <h3><Link to="/jobs/front-end-lead/">Senior / Lead Front-end Developer</Link></h3>
              <p>We are looking for a smart and ambitious developer to take on the responsibilities of leading the front-end development of the Getlocal SAAS e-commerce and site builder.</p>
              <p><Link to="/jobs/front-end-lead/" className="btn">Read more</Link></p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
)
